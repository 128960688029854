<template>
  <div class="flex bg-gray-lighter py-4 md:py-24 relative px-4 md:px-0">
    <div class="container mx-auto flex ">
      <div class="hidden md:block w-3/5">
        <p class="text-6xl text-primary font-semibold">Got a project?</p>
        <p class="text-6xl text-primary font-thin mb-6">Let’s talk</p>
        <a href="mailto:contact@coastart.ma" class="bg-primary px-3 py-2 text-sm text-white font-semibold">
          Get a Quote
        </a>

      </div>
      <div class="w-full md:w-2/5">
        <div class="flex">
          <div class="w-1/2">
            <p class="font-bold text-black mb-4">Links</p>
            <ul class="text-gray text-sm">
              <li>Home</li>
              <li>About</li>
              <li>Services</li>
              <li>Work</li>
              <li>Contact</li>
            </ul>
          </div>
          <div class="w-1/2 text-gray">
            <p class="font-bold text-black mb-4">Stay Connected</p>
            <p class="text-black text-sm md:text-xl mb-4">contact@coastart.uk</p>
            <p class="text-sm">
              71-75 Shelton Street, Covent Garden, London UK
            </p>
            <p>+447463627829</p>
          </div>
        </div>
        <!--  -->
        <div class="mt-8 flex flex-col flex-col-reverse md:flex-row items-center md:justify-between text-sm text-gray " >
          <div class="flex items-center mt-4 md:mt-0" >
            <span class="mr-4" >©Copyright 2024.</span>
            <img class="w-16" src="../assets/logo-small.png" alt="">
          </div>
          <div>
            <ul class="flex " >
              <li class="mr-2" >Privacy Policy</li>
              <li>Terms of Use</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style></style>
